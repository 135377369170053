@import "typography.scss";

html {
  font-family: $text-font;
}

//Scrollbar
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $scrollbar-background-shadow-color;
  background-color: $scrollbar-background-color;
}

::-webkit-scrollbar-thumb {
  background: $scrollbar-color;
}

::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}

.private-sale-loader-container {
  background-color: rgba($color: #ffffff, $alpha: 1);
}

.base-dashboard-loader-container {
  background-color: rgba($color: #ffffff, $alpha: 1);
}

::ng-deep.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: rgba(179, 174, 160, 1);
  box-shadow: 0 0 0 2px rgb(179 174 160 / 20%);
}

::ng-deep .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: rgba(179, 174, 160, 1);
}

::ng-deep .language-select {
  & .ant-select-arrow {
    //color: #fff !important;
    color: #000 !important;
  }

  //color: #fff;
  color: #000 !important;
}

.sale-event-scroll-down-button {
  display: none !important;
}

.booking-sale-detail-banner-container {
  max-height: 500px !important;
  background-position: top center !important;
}

.advanced-booking-sale-detail-banner-container {
  background-position: center center !important;


}

.advanced-sale-event-name {
  & .purple-sales-title-container {
    border: 0;
    border-bottom: 0 !important;
  }

  & h1 {
    color: black !important;
  }

  // & .purple-sales-title {
  //   color: white !important;
  // }
}

/* .advanced-booking-sale-detail-banner-container {
  max-height: 250px !important;
  background-position: top center !important;

  & .advanced-booking-sale-detail-banner-dark-layer {
    background-color: transparent !important;
    max-height: 250px !important;

    & .advanced-sale-event-name {
      display: none !important;
    }
  }
} */

.sw-border-radius {
  border-radius: $border-radius !important;
}

.mat-expansion-panel {
  border-radius: $border-radius !important;
}

.user-header-container {
  position: relative;
  color: white;

  & .user-menu-language-select {
    margin-left: 18px;
    min-width: 110px !important;
    color: white !important;

    & .ant-select-selector {
      background-color: rgba($color: white, $alpha: 0.05) !important;
      border: none !important;
      align-items: center;
      height: 40px !important;
      padding: 0 11px 0 15px;
      //padding-left: 0px;

      & .ant-select-selection-item {
        font-size: 16px;
        text-transform: capitalize;
        letter-spacing: 1px;
        color: white !important;
        font-weight: 700;
      }
    }
  }
}

.animation-container {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  z-index: 0 !important;
}

.purple-sales-footer-secondary {
  color: #ffffff !important;

  & a {
    color: #ffffff !important;
  }
}

.account-need-verification-banner-button {
  background: transparent !important;
}

.purple-sales-footer-image {
  height: 80px !important;
  object-fit: contain !important;
}

.sale-event-name {
  text-overflow: unset !important;
  text-wrap: balance !important;
}

app-purple-sales-page-title {
  margin-bottom: 8px;
}

.purple-sales-title-container {
  margin: 0;
  padding: 20px 0;
  background-color: transparent !important; //$footer-background-color-top !important;
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;



  & .purple-sales-title {
    //white-space: nowrap;
    padding: 0px 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
  }
}

.purple-sales-subtitle {
  color: black !important;
  padding: 0px 6px;
}

.class-calendar-carousel-day-container {
  background-color: $footer-background-color-top !important;
  border-radius: $border-radius !important;
}

.book-slot-modal-info-sale-event-logo {
  width: 100% !important;
  height: unset !important;
}


.booking-calendar-day-slots-item {
  cursor: pointer !important;
  border-radius: $border-radius !important;
}

.user-profile-reset-button-container {
  & button {
    border-radius: $border-radius !important;
  }
}

.user-profile-section-attributes {
  & button {
    border-radius: $border-radius !important;
  }
}

.user-bookings-stats-box-internal {
  border-radius: $border-radius !important;
}

.policy-items-container {
  & .policy-item {
    border-radius: $border-radius !important;
  }
}

.booking-calendar-active-booking-banner {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  border-radius: $border-radius !important;

  & button {
    border-radius: $border-radius !important;
    font-size: large;
    height: auto;
  }
}

.purple-sales-footer-menu-contact-text {
  font-family: $text-font;
  font-size: 12px !important;
}

.allround {
  border-radius: $border-radius !important;
}

.contact-form-box {
  border-radius: $border-radius !important;
}

.purple-sales-user-menu-popover-firstname {
  word-break: break-all !important;
  text-wrap-mode: balance !important;

}

.purple-sales-user-menu-popover-email {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.mobile-booking-calendar-days-container .mat-expansion-panel .mat-expansion-panel-body .collapse-content-container .booking-calendar-day-slots-item-container .booking-calendar-day-slots-item {
  background-color: $footer-background-color-top !important;

  & div {
    color: white !important;
  }

  & p {
    color: white !important;
  }

  & span {
    color: white !important;
  }

  & .slot-date {
    font-weight: lighter !important;
  }

  & .slot_item_booking_button_text {
    font-size: 24px !important;
    font-weight: bolder !important;
    max-width: unset !important;
  }
}

.auth-secondary-button {
  & .purple-sales-button {
    background-color: transparent !important;
    border: 0 !important;
    box-shadow: none !important;

    & .purple-sales-button-text {
      text-transform: capitalize !important;
      // font-size: 13px;
      padding: 0 !important;
      letter-spacing: unset !important;
      color: $primary-color !important;
    }
  }
}

.ant-form-item-explain {
  position: inherit !important;
  bottom: unset !important;
}

.ant-form-item-explain-error {
  position: inherit !important;
}

.class-calendar-carousel-day-name {
  color: #fff !important;
}

.class-calendar-carousel-day-number {
  color: #fff !important;
}