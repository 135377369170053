@import "variable.scss";

h1 {
  font-family: $title-font;
  font-size  : 35px;
  font-weight: 800;
  line-height: normal;
}

h2 {
  font-family:$title-font;
  font-size  : 35px;
  font-weight: 600;
  line-height: normal;
}

h3 {
  font-family: $title-font;
  font-size  : 21px;
  font-weight: 500;
  line-height: normal;
}

p {
  font-family: $text-font;
  font-size  : 14px;
  line-height: normal;
}

label {
  font-family: $text-font;
  font-size  : 14px;
  line-height: normal;
}

a {
  font-family: $text-font;
  font-size  : 14px;
  font-weight: 600;
}

@media screen and (max-width: 769px) {
  h1 {
    font-size  : 23px;
    font-weight: 700;
  }

  h2 {
    font-size  : 23px;
    font-weight: 600;
  }

  h3 {
    font-size  : 18px;
  }
}
