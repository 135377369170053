//Primary color
$primary-color:rgb(106, 106, 106);

//Generic
$border-radius: 22px;

//typography
$title-font: "Archivo", Sans-serif;
$text-font: "Archivo", Sans-serif;

$title-color: #000000;
$background-color: #fdfdfd;
$text-color: #000000;

//button
$button-border-radius: $border-radius;
$button-text-tranform: uppercase;

$button-default-background-color: #fff;
$button-default-text-color: #000;
$button-default-border-color: #000;

$button-default-background-hover-color: #fff;
$button-default-text-hover-color: rgba(0, 0, 0, 0.20);
$button-default-border-hover-color: rgba(0, 0, 0, 0.20);

$button-primary-background-color: $primary-color;
$button-primary-text-color: #fff;
$button-primary-border-color: $primary-color;

$button-primary-background-hover-color: #fff;
$button-primary-text-hover-color: rgba(0, 0, 0, 0.20);
$button-primary-border-hover-color: rgba(0, 0, 0, 0.20);

//Footer
$footer-background-color-top: #000000;
$footer-background-color-bottom: rgb(106, 106, 106);
$footer-height: 320px;
$max-container-width: 1600px;

//Header
$header-background-color: #000;
$header-button-text-color: #fff;
$header-button-text-color-open: rgb(255, 255, 255);
$header-button-selected-text-color: $primary-color;

$header-desktop-height: 90px;
$header-tablet-height: 90px;
$header-mobile-height: 70px;

$mobile-menu-background-color: #002959;
$mobile-menu-button-text-color: #fff;
$mobile-menu-button-selected-text-color: $primary-color;

//scrollbar
$scrollbar-background-color: $background-color;
$scrollbar-color: rgba($primary-color,.9);
$scrollbar-background-shadow-color: $background-color;

//animations
$animationY: all 0.3s cubic-bezier(0.25, 1.15, 0.5, 1.2);
$animationX: all 0.3s cubic-bezier(0.25, 1.15, 0.5, 1);

//Modal
$modal-title-color: #000000;
$modal-text-color: #000000;

:root {
    --app-height: 100%;
    --purple-lib-primary-color: #E30613;
    --purple-lib-font-size: 14px;
    --purple-lib-font-family: "Archivo", Sans-serif !important;
    --purple-lib-title-font-family: "Archivo", Sans-serif !important;
    --purple-lib-body-background: #E30613;
    --purple-lib-disabled-color: fade(#000, 55%);


    --cui-bg-accent-strong: #E30613;
    --cui-bg-accent-strong-hovered: #1c51d3;
    --cui-bg-accent-strong-pressed: #10399e;
    --cui-bg-accent-strong-disabled: rgba(48, 100, 227, .4);
}
